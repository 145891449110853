import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import RssFeed from "./RssFeed";

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const MethodsHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
              style={{ textAlign: "center" }}
            >
              Methods Overview
            </h1>
            <p style={{ textAlign: "center" }}>
              {" "}
              Maintaining consistency and transparency for how savings are
              calculated. We subscribe fully to the IPMVP Guidance regarding the
              basis for assessing adherence to standard Measurement and
              Validation Methods:
            </p>
            <ul style={{ listStyleType: "none" }}>
              <li>
                Accurate - M&V reports are as accurate as can be justified based
                on the project value and the requirements of the local
                Commission approving the program, with increased
                conservativeness with increased use of estimated values and
                judgments.
              </li>
              <li>
                Complete - The reporting of energy savings considers all effects
                of a project, with measurements to quantify the significant
                effects, while estimating others.
              </li>
              <li>
                Conservative - M&V procedures are designed to responsibly
                estimate savings. The energy-saving benefits are both reasonable
                and conservative with due consideration to the level confidence
                of the estimation.
              </li>
              <li>
                Consistent - A project's energy performance is consistent and
                comparable across: Different types of energy efficiency
                projects, different energy management professionals for any
                project, different periods of time for the same project.
              </li>
              <li>
                Relevant - Savings is based on current measurements and
                information pertaining to where the project occurs. This
                determination of saving effort must measure the performance
                parameters that are of main concern, or that are least well
                known.
              </li>
              <li>
                Transparent - M&V activities should be clearly documented and
                fully disclosed, to include all elements of a M&V plan and
                saving reports. Our data and information collection, preparation
                techniques, algorithms, spreadsheets, software, assumptions
                used, and analysis follow industry standard practices. It is
                well formatted and documented – such that any outside reviewer
                can understand how the data and analysis conformed to the M&V
                plan and savings reporting procedures.
              </li>
            </ul>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              ></p>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Image
              className="has-shadow"
              src={require("./../../assets/images/IPMVP-4-Option-Graphic[44].png")}
              alt="Hero"
              width={896}
              height={504}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

MethodsHero.propTypes = propTypes;
MethodsHero.defaultProps = defaultProps;

export default MethodsHero;

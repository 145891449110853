import React, { useState, setState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { send } from "emailjs-com";
import { useLocation } from 'react-router-dom'


import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const EnergyAuditForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const initialFormData = Object.freeze({
    firstName: "",
    lastName: "",
    streetAddress: "",
    state: "NM",
    zipcode: "",
    email: "",
    phone: "",
    assessment: "",
    referral: "",
    message: "",
    sent: false,
    buttonText: "Send Message",
    emailError: false
  });
  const [formData, setformData] = useState({
    ...initialFormData,
    state: "NM"
  });
  const location = useLocation();
  const assessment = location.state?.assessment;
  console.dir(location);


  const history = useHistory();

  const handleValueChange = async e => {


    setformData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Energy Efficiency Experts",
    paragraph:
      "We provide the tools and expertise to combat unnecessary demand on the energy grid. In coordination with our utility partners, we identify the source of wasted energy and replace it with top-rated energy efficient solutions."
  };
  const formSubmit = async e => {
    e.preventDefault();
    setformData({
      ...formData,
      buttonText: "...sending"
    });

    let data = formData;
    try {
      setformData({
        ...formData,
        sent: true
      });
      e.preventDefault();
      send(
        "service_i2ct6ho",
        "template_s8dehgk",
        data,
        "st2saQEJf2ZOQ7coA"
      ).then(
        result => {
          console.log(result.text);
          history.push("/");
        },
        error => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
    } catch (error) {
      //  resetForm();
      // let response = await fetch("http://localhost:5000/contact", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json;charset=utf-8",
      //   },
      //   body: JSON.stringify(formData),
      // });
      //   console.log(data);
      //   let result = await response.json();
      console.log(error);
    }
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className="custom-section-inner">
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <FormControl fullWidth>
            <Grid>
              <Card style={{ padding: "10px 5px", margin: "0 auto" }}>
                <CardContent>
                  <form onSubmit={e => formSubmit(e)}>
                    <Grid container spacing={4}>
                      <Grid xs={12} sm={6} item>
                        <TextField
                          placeholder="Enter first name"
                          label="First Name"
                          variant="outlined"
                          name="firstName"
                          value={formData.firstName}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        />
                      </Grid>
                      <Grid xs={12} sm={6} item>
                        <TextField
                          placeholder="Enter last name"
                          label="Last Name"
                          variant="outlined"
                          name="lastName"
                          value={formData.lastName}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        <TextField
                          placeholder="Enter street address"
                          label="Street Address"
                          variant="outlined"
                          name="streetAddress"
                          value={formData.streetAddress}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        />
                      </Grid>

                      <Grid xs={12} sm={4} item>
                        <TextField
                          placeholder="Enter city"
                          label="City"
                          variant="outlined"
                          name="city"
                          value={formData.city}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        />
                      </Grid>

                      <Grid xs={12} sm={4} item>
                        <Select
                          placeholder="Select state"
                          label="State"
                          variant="outlined"
                          name="state"
                          value={formData.state}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        >
                          <MenuItem value="AL">AL</MenuItem>
                          <MenuItem value="AK">AK</MenuItem>
                          <MenuItem value="AZ">AZ</MenuItem>
                          <MenuItem value="AR">AR</MenuItem>
                          <MenuItem value="CA">CA</MenuItem>
                          <MenuItem value="CO">CO</MenuItem>
                          <MenuItem value="CT">CT</MenuItem>
                          <MenuItem value="DE">DE</MenuItem>
                          <MenuItem value="DC">DC</MenuItem>
                          <MenuItem value="FL">FL</MenuItem>
                          <MenuItem value="GA">GA</MenuItem>
                          <MenuItem value="HI">HI</MenuItem>
                          <MenuItem value="ID">ID</MenuItem>
                          <MenuItem value="IL">IL</MenuItem>
                          <MenuItem value="IN">IN</MenuItem>
                          <MenuItem value="IA">IA</MenuItem>
                          <MenuItem value="KS">KS</MenuItem>
                          <MenuItem value="KY">KY</MenuItem>
                          <MenuItem value="LA">LA</MenuItem>
                          <MenuItem value="ME">ME</MenuItem>
                          <MenuItem value="MD">MD</MenuItem>
                          <MenuItem value="MA">MA</MenuItem>
                          <MenuItem value="MI">MI</MenuItem>
                          <MenuItem value="MN">MN</MenuItem>
                          <MenuItem value="MS">MS</MenuItem>
                          <MenuItem value="MO">MO</MenuItem>
                          <MenuItem value="MT">MT</MenuItem>
                          <MenuItem value="NE">NE</MenuItem>
                          <MenuItem value="NV">NV</MenuItem>
                          <MenuItem value="NH">NH</MenuItem>
                          <MenuItem value="NJ">NJ</MenuItem>
                          <MenuItem value="NM">NM</MenuItem>
                          <MenuItem value="NY">NY</MenuItem>
                          <MenuItem value="NC">NC</MenuItem>
                          <MenuItem value="ND">ND</MenuItem>
                          <MenuItem value="OH">OH</MenuItem>
                          <MenuItem value="OK">OK</MenuItem>
                          <MenuItem value="OR">OR</MenuItem>
                          <MenuItem value="PA">PA</MenuItem>
                          <MenuItem value="RI">RI</MenuItem>
                          <MenuItem value="SC">SC</MenuItem>
                          <MenuItem value="SD">SD</MenuItem>
                          <MenuItem value="TN">TN</MenuItem>
                          <MenuItem value="TX">TX</MenuItem>
                          <MenuItem value="UT">UT</MenuItem>
                          <MenuItem value="VT">VT</MenuItem>
                          <MenuItem value="VA">VA</MenuItem>
                          <MenuItem value="WA">WA</MenuItem>
                          <MenuItem value="WV">WV</MenuItem>
                          <MenuItem value="WI">WI</MenuItem>
                          <MenuItem value="WY">WY</MenuItem>
                        </Select>
                      </Grid>

                      <Grid xs={12} sm={4} item>
                        <TextField
                          placeholder="Enter zipcode"
                          label="Zipcode"
                          variant="outlined"
                          name="zipcode"
                          value={formData.zipcode}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="email"
                          placeholder="Enter email address"
                          label="Email"
                          variant="outlined"
                          name="email"
                          value={formData.email}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="number"
                          placeholder="Enter phone number"
                          label="Phone"
                          variant="outlined"
                          name="phone"
                          value={formData.phone}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        />
                      </Grid>
                      <Grid xs={12} item>
                        {/* <InputLabel id="demo-simple-select-label">Something</InputLabel> */}
                        <FormControl style={{ width: "100%" }}>
                        <InputLabel id="test-select-label">
                            Assessment Type
                          </InputLabel>

                        <Select
                          labelId="test-select-label"
                          // id="demo-simple-select"
                          placeholder="Type of Assessment"
                          label="Assessment Type"
                          variant="outlined"
                          name="assessment"
                          value={formData.assessment}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        >
                          <MenuItem value="Standard">Standard</MenuItem>
                          <MenuItem value="Advanced">Advanced</MenuItem>
                        </Select>
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={12} >
                      <TextField
                        placeholder="What date are you available"
                        label="Availability"
                        variant="outlined"
                        name="availability"
                        value={formData.available}
                        fullWidth
                        required
                        onChange={handleValueChange}
                      />
                    </Grid> */}
                      <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="test-select-label">
                            How did you hear about us?
                          </InputLabel>

                          <Select
                            placeholder="How did you hear about us?"
                            label="How did you hear about us?"
                            labelId="test-select-label"
                            variant="outlined"
                            name="referral"
                            value={formData.referral}
                            fullWidth
                            required
                            onChange={handleValueChange}
                          >
                            <MenuItem value="Bill Insert">Bill Insert</MenuItem>
                            <MenuItem value="Billboard">Billboard</MenuItem>
                            <MenuItem value="Door to Door Canvassing">
                              Door to Door Canvassing
                            </MenuItem>
                            <MenuItem value="Email">Email</MenuItem>
                            <MenuItem value="Event">Event</MenuItem>
                            <MenuItem value="Friends/Family">
                              Friends/Family
                            </MenuItem>
                            <MenuItem value="Mail/Bill Insert">
                              Mail/Bill Insert
                            </MenuItem>
                            <MenuItem value="Military Veterans Email">
                              Military Veterans Email
                            </MenuItem>
                            <MenuItem value="Newspaper">Newspaper</MenuItem>
                            <MenuItem value="Newsletter">Newsletter</MenuItem>
                            <MenuItem value="Radio">Radio</MenuItem>
                            <MenuItem value="Search Engine">
                              Search Engine
                            </MenuItem>
                            <MenuItem value="Social Media">
                              Social Media
                            </MenuItem>
                            <MenuItem value="Newsletter">Newsletter</MenuItem>
                            <MenuItem value="Newsletter">Newsletter</MenuItem>
                          </Select>
                        </FormControl>
                        {/* <TextField
                        placeholder="How did you hear about us"
                        label="How did you hear about our energy audits"
                        variant="outlined"
                        name="referral"
                        value={formData.referral}
                        fullWidth
                        required
                        onChange={handleValueChange}
                      /> */}
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Message"
                          multiline
                          rows={4}
                          placeholder="What you like to know or what do you want us to know?"
                          variant="outlined"
                          name="message"
                          value={formData.message}
                          fullWidth
                          required
                          onChange={handleValueChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </FormControl>
        </div>
      </div>
    </section>
  );
};

EnergyAuditForm.propTypes = propTypes;
EnergyAuditForm.defaultProps = defaultProps;

export default EnergyAuditForm;

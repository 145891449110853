import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const ResidentialEnergyAssessmentsAspects = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: " Energy Assessment Steps",
    paragraph: ""
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <p>
            Energy assessors follow these steps in order to comprehend the
            home’s current energy use and to offer recommendations to reduce
            energy waste.
          </p>

          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  {/* <Link
                    to="/services/installations"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  > */}
                  Homeowner Interview {/* </Link> */}
                </h3>
                <p className="m-0">
                  A discussion with the homeowner to identify any specific
                  comfort issues and understand typical living patterns in the
                  home.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                {/* <Link
                  to="/services/installations"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                > */}
                <Image
                  title="U.S. Department of Energy"
                  src={require("./../../assets/images/assessor.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                {/* </Link> */}
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  {/* <Link
                    to="/services/installations"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  > */}
                  Energy Bill Review {/* </Link> */}
                </h3>
                <p className="m-0">
                  A review of historical energy use in the home, if available,
                  to serve as a benchmark for projected savings estimates.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                {/* <Link
                  to="/services/installations"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                > */}
                <Image
                  title="Department of Energy"
                  src={require("./../../assets/images/image 49.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                {/* </Link> */}
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  {/* <Link
                    to="/services/audits"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  > */}{" "}
                  Home Evaluation {/* </Link> */}
                </h3>
                <p className="m-0">
                  Visually inspect the exterior, interior, home envelope, and
                  all mechanical systems to determine the home’s conditions.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Department of Energy"
                  src={require("./../../assets/images/image 50.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Thermographic Inspection{" "}
                </h3>
                <p className="m-0">
                  An infrared camera is used for assessors to easily identify
                  where heating/cooling loss is occurring in the home due to air
                  leakage or deficiencies in insulation.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Department of Energy"
                  src={require("./../../assets/images/image 48.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                <p></p>
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Blower Door Test{" "}
                </h3>
                <p className="m-0">
                  Diagnostic equipment is installed on a home’s external door
                  and blows air from the building in order to create negative
                  air pressure inside. This will allow the assessor to
                  understand the the building’s overall tightness and
                  exacerbates air leakage so that the leaks can be more easily
                  identified.{" "}
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Department of Energy"
                  src={require("./../../assets/images/28603-C_0.jpeg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Home Improvement Recommendations{" "}
                </h3>
                <p className="m-0">
                  After the assessor is finished with the on-site inspection, a
                  report with a prioritized list of improvement measures based
                  on health and safety issues, comfort, and overall cost-benefit
                  is provided to the homeowner.{" "}
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Department of Energy"
                  src={require("./../../assets/images/Tax credits and rebates_4.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ResidentialEnergyAssessmentsAspects.propTypes = propTypes;
ResidentialEnergyAssessmentsAspects.defaultProps = defaultProps;

export default ResidentialEnergyAssessmentsAspects;

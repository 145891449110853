import React from 'react';
import AnalyticsHero from '../../components/sections/Analytics/AnalyticsHero';
import AnalyticsSplit from '../../components/sections/Analytics/AnalyticsSplit';

const Analytics = () => {
    return(
        <>
        <AnalyticsHero />
        <AnalyticsSplit />
        </>
    )
}

export default Analytics
import React from 'react';
// import sections
import HomeHero from '../components/sections/HomeHero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import FaqHero from '../components/sections/FaqHero';


const FAQ = () => {

  return (
    <>
      <FaqHero className="illustration-section-01" />

      {/* <FeaturesTiles /> */}
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default FAQ;
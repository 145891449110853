import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Button from "../elements/Button";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const HomeMVTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "What We Provide",
    paragraph: ""
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/pen.png")}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Direct Install Program Management
                  </h4>
                  <p className="m-0 text-sm">
                    Manage Utility/Government Funded Incentive Programs{" "}
                  </p>
                  <Link
                    to="/services"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "white",
                        border: "#9CA9B3 solid 1px"
                      }}
                    >
                      Learn More Here
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/dollar-management.png")}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Home Energy Assessments
                  </h4>
                  <p className="m-0 text-sm">
                    Start the Journey For a More Energy Efficient Home
                  </p>
                  <Link
                    to="/services/energy-assessments-signup"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "white",
                        border: "#9CA9B3 solid 1px"
                      }}
                    >
                      Learn More Here
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

            {/* <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/business-dollar-management-svgrepo-com.svg')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                  Economic Impacts
                    </h4>
                  <p className="m-0 text-sm">
                  How M&V affects the local community with direct and indirect benefits.
                    </p>
                    <Link
                  to="/services/economic-impacts"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                <Button style = {{backgroundColor:"white", border:"#9CA9B3 solid 1px"}}>
                    Learn More Here
                </Button>
                </Link>
                </div>
              </div>
            </div> */}

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/growth-management.png")}
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Measurement and Verification{" "}
                  </h4>
                  <p className="m-0 text-sm">An Overview of Our M&V Process.</p>
                  <Link
                    to="/services/analytics"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "white",
                        border: "#9CA9B3 solid 1px"
                      }}
                    >
                      Learn More Here
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
           
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/dollar-management.png")}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                    Rebate Processing
                  </h4>
                  <p className="m-0 text-sm">
                    Ensure compliance with utility and/or regulatory agency{" "}
                  </p>
                  <Link
                    to="/services/energy-assessments-signup"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "white",
                        border: "#9CA9B3 solid 1px"
                      }}
                    >
                      Learn More Here
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/dollar-management.png")}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8 text-color-primary">
                  Electrification Education
                                    </h4>
                  <p className="m-0 text-sm">
                  Start the Journey For a More Energy
Efficient Home                  </p>
                  <Link
                    to="/services/energy-assessments-signup"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "white",
                        border: "#9CA9B3 solid 1px"
                      }}
                    >
                      Learn More Here
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HomeMVTiles.propTypes = propTypes;
HomeMVTiles.defaultProps = defaultProps;

export default HomeMVTiles;

import React from "react";
import SectionHeader from "../../components/sections/partials/SectionHeader";
import classNames from "classnames";
import MVChecklist from "../../components/sections/MVChecklist";
import Image from "../../components/elements/Image";
import EnergyEvaluationHero from "../../components/sections/EnergyEvalutationHero";
import ServicesMVSplit from "../../components/sections/ServicesMVSplit";
import DirectInstallProgramBenefits from "../../components/sections/DirectInstallProgramBenefits";
import DirectInstallProgramAspects from "../../components/sections/DirectInstallProgramAspects";
import ResidentialEnergyAssessmentsHero from "../../components/sections/ResidentialEnergyAssessmentsHero";
import ResidentialEnergyAssessmentsAspects from "../../components/sections/ResidentialEnergyAssessmentsAspects";

const ResidentialEnergyAssessments = () => {
    return (
    <>
      <ResidentialEnergyAssessmentsHero />
    
      {/* <MVChecklist /> */}
      <ResidentialEnergyAssessmentsAspects />


    </>
  );
};

export default ResidentialEnergyAssessments;

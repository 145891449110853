import React from 'react';
import ContactUsHero from '../../components/sections/ContactUsHero';
import ContactUsForm from '../../components/sections/ContactUsForm';

const Contact = () => {
    
    return(
        <>
      <ContactUsHero />
      <ContactUsForm />
      </>
    )
}

export default Contact
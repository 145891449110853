import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const EconomicSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Economic Impacts",
    paragraph:
      "We provide the tools and expertise to combat unnecessary demand on the energy grid. In coordination with our utility partners, we identify the source of wasted energy and replace it with top-rated energy efficient solutions."
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  {" "}
                  Cost Estimates Per Measure Implemented
                </h3>
                <p className="m-0">
                  Cost-Effectiveness of equipment and labor for installed
                  measures.
                </p>
              </div>
              <div
                title="Photo by micheile dot com on Unsplash"
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Photo by micheile dot com on Unsplash"
                  src={require("./../../assets/images/micheile-dot-com-ZVprbBmT8QA-unsplash.jpg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
                {/* <p>
                  Photo by{" "}
                  <a href="https://unsplash.com/@micheile?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                    {" "}
                    micheile dot com
                  </a>{" "}
                  on{" "}
                  <a href="https://unsplash.com/?utm_medium=referral">
                    Unsplash
                  </a>{" "}
                </p> */}
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Cost-Effectiveness Analysis
                </h3>
                <p className="m-0">
                  Benefits of implementing program-level Energy Efficiency measures compared
                  to cost of avoided generation.
                </p>
              </div>
              <div
              title="Photo by Mika Baumeister on Unsplash"
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/mika-baumeister-Wpnoqo2plFA-unsplash.jpg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                {/* <p>
                  Photo by{" "}
                  <a href="https://unsplash.com/@mbaumi?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                    Mika Baumeister
                  </a>{" "}
                  on{" "}
                  <a href="https://unsplash.com/?utm_medium=referral">
                    Unsplash
                  </a>
                </p> */}
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Non-Energy Benefits
                </h3>
                <p className="m-0">
                  Highlighting the non-Energy centric benefits of Energy
                  Efficiency including
                    avoided emissions, health benefits, job creation, local
                    economic development, energy security, transmission and
                    distribution benefits, and reducing social vulnerability.
                
                </p>
              </div>
              <div
                title="Photo by Jasmin Sessler on Unsplash"
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/jasmin-sessler-sRJEcdEhyZw-unsplash.jpg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                {/* <p>
                  Photo by{" "}
                  <a href="https://unsplash.com/@jasmin_sessler?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                    Jasmin Sessler
                  </a>{" "}
                  on{" "}
                  <a href="https://unsplash.com/?utm_medium=referral">
                    Unsplash
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

EconomicSplit.propTypes = propTypes;
EconomicSplit.defaultProps = defaultProps;

export default EconomicSplit;

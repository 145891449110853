import React from "react";
import SectionHeader from "../../components/sections/partials/SectionHeader";
import classNames from "classnames";
import MVChecklist from "../../components/sections/MVChecklist";
import Image from "../../components/elements/Image";
import EnergyEvaluationHero from "../../components/sections/EnergyEvalutationHero";
import ServicesMVSplit from "../../components/sections/ServicesMVSplit";
import DirectInstallProgramHero from "../../components/sections/DirectInstallProgramHero";
import DirectInstallProgramBenefits from "../../components/sections/DirectInstallProgramBenefits";
import DirectInstallProgramAspects from "../../components/sections/DirectInstallProgramAspects";

const DirectInstallProgram = () => {
    return (
    <>
      <DirectInstallProgramHero />
      <DirectInstallProgramBenefits />
    
      {/* <MVChecklist /> */}
      <DirectInstallProgramAspects />


    </>
  );
};

export default DirectInstallProgram;

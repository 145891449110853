import React from "react";

import EnergyAuditForm from "../../components/sections/EnergyAuditForm";
import EnergyAuditFormHero from "../../components/sections/EnergyAuditFormHero";


const EnergyAuditsForm = () => {
    return (
    <>
      <EnergyAuditFormHero/>
      <EnergyAuditForm invertMobile topDivider imageFill className="illustration-section-02" />

    </>
  );
};

export default EnergyAuditsForm;

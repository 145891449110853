import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../../utils/SectionProps";
import SectionHeader from "../partials/SectionHeader";
import Image from "../../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const AnalyticsSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Energy Efficiency Experts",
    paragraph:
      "We provide the tools and expertise to combat unnecessary demand on the energy grid. In coordination with our utility partners, we identify the source of wasted energy and replace it with top-rated energy efficient solutions."
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  {" "}
                  Deemed Savings
                </h3>
                <p className="m-0">
                  Although not classic measurement and verification, the
                  evaluation of well-established Energy Saving measures that
                  contribute to Energy Efficiency described in multiple
                  Technical Resource Manuals (TRM).
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Photo by Scott Graham on Unsplash"
                  src={require("./../../../assets/images/scott-graham-5fNmWej4tAA-unsplash.jpg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  {" "}
                  Comprehensive Data Visualization
                </h3>
                <p className="m-0">
                  Data visualizations are created for markets, climate zones,
                  measures, and savings from large scale statistical analysis.
                  This helps determine the total energy and monetary savings
                  produced by the energy efficiency measures.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="efis.psc.mo.gov"
                  src={require("./../../../assets/images/Baseline-graph.png")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Predictive Analysis
                </h3>
                <p className="m-0">
                  Different modeling softwares are used to build energy usage
                  models to help predict the energy demand.
                  Uncertainty  is calculated to determine 
                  accuracy, then energy consumption is
                  appropriately adjusted.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../../assets/images/Linear_Regression.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

AnalyticsSplit.propTypes = propTypes;
AnalyticsSplit.defaultProps = defaultProps;

export default AnalyticsSplit;

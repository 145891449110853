import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Energy Efficiency Experts",
    paragraph:
      "We provide the tools and expertise to combat unnecessary demand on the energy grid. In coordination with our utility partners, we identify the source of wasted energy and replace it with top-rated energy efficient solutions."
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  <Link
                    to="/services/audits"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    {" "}
                    Energy Efficiency Audits
                  </Link>
                </h3>
                <p className="m-0">
                  Our Energy Assessors follow the Department of Energy's Energy
                  Score procedures to evaluate your customer's homes for Energy
                  Efficiency. Areas evaluated include lighting, air
                  infiltration, insulation and water usage.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Link
                  to="/services/audits"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <Image
                    src={require("./../../assets/images/energysaver2017_House.png")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                </Link>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  <Link
                    to="/services/installations"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Installation Operations
                  </Link>
                </h3>
                <p className="m-0">
                  Following the energy audit's recommendations, our energy
                  efficency experts will replace wasteful equipment and
                  appliances and install energy-saving equivalents.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Link
                  to="/services/installations"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <Image
                    src={require("./../../assets/images/installation-lighting.jpg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </Link>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  <Link
                    to="/services/reporting"
                    classname="text-color-primary"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Incentive Processing
                  </Link>
                </h3>
                <p className="m-0">
                  Processing utility rebates of energy efficient appliances,
                  heating, air conditioning and weatherization.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Link
                  to="/services/reporting"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <Image
                    src={require("./../../assets/images/campaign-creators-pypeCEaJeZY-unsplash.jpg")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                </Link>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Electric Vehicle Charging Station Maintenance
                </h3>
                <p className="m-0">
                  As the electric cars are becoming more the norm for everyday
                  transportation, the maintenance of the charging stations is
                  becoming as important as the maintenance of fossil fuel pumps.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/michael-marais-HjV_hEECgcM-unsplash.jpg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;

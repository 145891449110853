import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const EnergyEvaluationHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom text-color-primary"
              data-reveal-delay="200"
              style={{ textAlign: "center" }}
            >
              Energy Program <br></br> Measurement and Verification<br></br>Core Services
            </h1>
            <p style={{ textAlign: "left" }}>
              <ul>
                <li>
                  Develop a reliable baseline for assessing investments in energy efficiency with savings targets compliance.
                </li>
                <li>
                Determine program cost effectiveness of approved program plans. 
                </li>
                <li>
                  Assess the performance of
                  energy efficiency and load management programs.
                </li>
                <li>
                  Recommend improvements on program performance.
                </li>
                <li>
                  Confirm  measures and programs were
                  implemented correctly and met reasonable quality standards.
                </li>
                <li>
                  Utilize applicable international performance measurement
                  and verification protocols, describe any deviations from those protocols, and explain the reason for the deviations.
                </li>
                <li>
                  Evaluate data collection methods to facilitate the M&V
                  process.
                  </li>
              </ul>
            </p>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              ></p>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Image
              className="has-shadow"
              src={require("./../../assets/images/energy-integration-diagram.jpg")}
              alt="Hero"
              width={896}
              height={504}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

EnergyEvaluationHero.propTypes = propTypes;
EnergyEvaluationHero.defaultProps = defaultProps;

export default EnergyEvaluationHero;

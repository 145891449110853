import React from "react";
import SectionHeader from "../../components/sections/partials/SectionHeader";
import classNames from "classnames";
import MVChecklist from "../../components/sections/MVChecklist";
import Image from "../../components/elements/Image";
import EnergyEvaluationHero from "../../components/sections/EnergyEvalutationHero";
import ServicesMVSplit from "../../components/sections/ServicesMVSplit";

const EnergyEvaluation = () => {
    return (
    <>
      <EnergyEvaluationHero />
      {/* <MVChecklist /> */}
      <ServicesMVSplit invertMobile topDivider imageFill className="illustration-section-02" />

    </>
  );
};

export default EnergyEvaluation;

import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const CompanyGoals = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
              style={{ textAlign: "center" }}
            >
              Our Promise
            </h1>
            <p style={{ textAlign: "center" }}>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  Use a reliable basis for assessments of investments in energy
                  efficiency.
                </li>
                <li>
                  {" "}
                  Apply evaluation methods that strengthen the credibility of
                  energy efficiency programs.
                </li>
                <li>
                  Document clear step-by-step procedures to determine savings.
                </li>
                <li>
                  {" "}
                  Calculate Savings with consistency and transparency.
                </li>
                {/* <li> Allow comparison of savings across programs.</li> */}
                <li>
                  {" "}
                  Guarantee acceptability of reported energy savings to
                  regulatory agencies.
                </li>
              </ul>
            </p>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              ></p>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <Image
              className="has-shadow"
              src={require("./../../assets/images/Handymen.jpg")}
              alt="Hero"
              width={896}
              height={504}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

CompanyGoals.propTypes = propTypes;
CompanyGoals.defaultProps = defaultProps;

export default CompanyGoals;

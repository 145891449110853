import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const InstallationSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Energy Efficiency Experts",
    paragraph:
      "We provide the tools and expertise to combat unnecessary demand on the energy grid. In coordination with our utility partners, we identify the source of wasted energy and replace it with top-rated energy efficient solutions."
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
              
                    {" "}
                    Lighting Replacement
                </h3>
                <p className="m-0">
                 Energy Saving LED light bulbs replace wasteful incandescant and CFL bulbs.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                  <Image
                    src={require("./../../assets/images/lightbulb-postit.png")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Weatherization
                </h3>
                <p className="m-0">
                  Insulation, pipe wrapping, and air infiltation reduction.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                  <Image
                    src={require("./../../assets/images/pipe-wrap-stack.jpeg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                 
                    Appliance Recommendations
                </h3>
                <p className="m-0">
                  We determine if the homeowner's appliances are eligible for rebates from utility clients.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                  <Image
                    src={require("./../../assets/images/appliances.jpg")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

InstallationSplit.propTypes = propTypes;
InstallationSplit.defaultProps = defaultProps;

export default InstallationSplit;

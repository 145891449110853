import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const ResidentialEnergyAssessmentsHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom text-color-primary"
              data-reveal-delay="200"
              style={{ textAlign: "center" }}
            >
              Residential Energy Assessments
            </h1>
            <div
              className="hero-figure reveal-from-bottom illustration-element-01"
              data-reveal-value="20px"
              data-reveal-delay="800"
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/energyaudithr01_0.jpeg")}
                alt="Hero"
                width={896}
                height={504}
              />
            </div>
            <div>
              <h1
                className="mt-0 mb-16"
                data-reveal-delay="200"
                style={{ textAlign: "center" }}
              >
                The Importance of Energy Assessments{" "}
              </h1>
              <p style={{ textAlign: "left" }}>
              
A professional energy assessment can help homeowners determine how much energy their home uses, where the home is inefficient, and which problem areas and fixes they should prioritize to save energy.


              </p>
            </div>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ResidentialEnergyAssessmentsHero.propTypes = propTypes;
ResidentialEnergyAssessmentsHero.defaultProps = defaultProps;

export default ResidentialEnergyAssessmentsHero;

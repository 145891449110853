import React from 'react';
// import sections
import HomeHero from '../components/sections/HomeHero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import ServicesMVSplit from '../components/sections/ServicesMVSplit';
import CompanyGoals from '../components/sections/CompanyGoals';
import RssFeed from '../components/sections/RssFeed';
import HomeMVSplit from '../components/sections/HomeMVSpilit';
import HomeMVTiles from '../components/sections/HomeMVTiles';
import WhyChooseUs from '../components/sections/WhyChooseUs';


const Home = () => {

  return (
    <>
      <HomeHero className="illustration-section-01" />
      <HomeMVTiles />
      <CompanyGoals />
      <WhyChooseUs />
      {/* <HomeMVSplit /> */}
      {/* <ServicesMVSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      {/* <RssFeed /> */}
      {/* <FeaturesTiles /> */}
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Home;
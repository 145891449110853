import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const EnergyAuditHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom text-color-primary"
              data-reveal-delay="200"
            >
              Home Energy Assessments in Northern New Mexico
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              ></p>
              <ul style={{ textAlign: "left" }}>
                <li>
                  Assessments are available to residents of Colfax, Los Alamos, Rio Arriba and Taos county.
                </li>
                <li>
                  We identify the aspects of your home that are wasting energy:
                </li>
                <li style={{ marginLeft: "30px" }}>Lighting</li>
                <li style={{ marginLeft: "30px" }}>Air Infiltration</li>
                <li style={{ marginLeft: "30px" }}>
                  Cooling and Heating Appliances
                </li>
                <li style={{ marginLeft: "30px" }}>Water Heating Appliances</li>
                <li>
                  We make recommendations that will save money on utility bills
                  and reduce CO<sub>2</sub> emissions.
                </li>
                <li>
                  Provides a roadmap with information that saves money and
                  energy. This report evaluates air leakage, lighting, HVAC,
                  appliances, and insulation. 
                </li>

                <li>
                  <strong>
                    Starting January 2023, $150 tax credit for energy audits.
                  </strong>
                </li>
              </ul>
              <Link
                to="/services/energy-assessments-signup/pricing"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                <Button
                  style={{
                    backgroundColor: "white",
                    border: "#9CA9B3 solid 1px"
                  }}
                >
                  Check Pricing!
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

EnergyAuditHero.propTypes = propTypes;
EnergyAuditHero.defaultProps = defaultProps;

export default EnergyAuditHero;

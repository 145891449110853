import React from "react";
import SectionHeader from "../../components/sections/partials/SectionHeader";
import classNames from "classnames";
import MVChecklist from "../../components/sections/MVChecklist";
import Image from "../../components/elements/Image";
import EnergyEvaluationHero from "../../components/sections/EnergyEvalutationHero";
import ServicesAssessmentSplit from "../../components/sections/ServicesAssessmentSplit";
import ServicesHero from "../../components/sections/ServicesHero";

const Services = () => {
    return (
    <>
      <ServicesHero />
      {/* <MVChecklist /> */}
      <ServicesAssessmentSplit invertMobile topDivider imageFill className="illustration-section-02" />

    </>
  );
};

export default Services;

import React from 'react';
import AboutUsHero from '../../components/sections/AboutUS/AboutUsHero';
import AboutUsText from '../../components/sections/AboutUS/AboutUsText';
import OurTeam from '../../components/sections/OurTeam';
import Mission from '../../components/sections/Mission';
const AboutUs = () => {
    return(
        <>
        {/* <AboutUsText /> */}
        <AboutUsHero />
        <Mission />
        <OurTeam />
        </>
    )
}

export default AboutUs
import React, { useState, setState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { send } from "emailjs-com";

import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Typography
} from "@mui/material";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const ContactUsForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const initialFormData = Object.freeze({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phone: null,
    message: "",
    sent: false,
    buttonText: "Send Message",
    emailError: false
  });
  const [formData, setformData] = useState({
    initialFormData
  });

  const history = useHistory();

  const handleValueChange = async e => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Energy Efficiency Experts",
    paragraph:
      "We provide the tools and expertise to combat unnecessary demand on the energy grid. In coordination with our utility partners, we identify the source of wasted energy and replace it with top-rated energy efficient solutions."
  };
  const formSubmit = async e => {
    e.preventDefault();
    setformData({
      ...formData,
      buttonText: "...sending"
    });

    let data = formData;
    try {
      setformData({
        ...formData,
        sent: true
      });
      e.preventDefault();
      send(
        "service_i2ct6ho",
        "template_0fzlsd9",
        data,
        "st2saQEJf2ZOQ7coA"
      ).then(
        result => {
          console.log(result.text);
          history.push('/');
          
        },
        error => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
    } catch (error) {
      //  resetForm();
      // let response = await fetch("http://localhost:5000/contact", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json;charset=utf-8",
      //   },
      //   body: JSON.stringify(formData),
      // });
      //   console.log(data);
      //   let result = await response.json();
      console.log(error);
    }
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className="custom-section-inner">
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <Grid>
            <Card style={{ padding: "10px 5px", margin: "0 auto" }}>
              <CardContent>
                <form onSubmit={e => formSubmit(e)}>
                  <Grid container spacing={4}>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        placeholder="Enter first name"
                        label="First Name"
                        variant="outlined"
                        name="firstName"
                        value={formData.firstName}
                        fullWidth
                        required
                        onChange={handleValueChange}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        placeholder="Enter last name"
                        label="Last Name"
                        variant="outlined"
                        name="lastName"
                        value={formData.lastName}
                        fullWidth
                        required
                        onChange={handleValueChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        placeholder="Company Name"
                        label="Company Name"
                        variant="outlined"
                        name="companyName"
                        value={formData.companyName}
                        fullWidth
                        required
                        onChange={handleValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        type="email"
                        placeholder="Enter Business Email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={formData.email}
                        fullWidth
                        required
                        onChange={handleValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        type="number"
                        placeholder="Enter phone number"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        value={formData.phone}
                        fullWidth
                        required
                        onChange={handleValueChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Message"
                        multiline
                        rows={4}
                        placeholder="Type your message here"
                        variant="outlined"
                        name="message"
                        value={formData.message}
                        fullWidth
                        required
                        onChange={handleValueChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </div>
      </div>
    </section>
  );
};

ContactUsForm.propTypes = propTypes;
ContactUsForm.defaultProps = defaultProps;

export default ContactUsForm;

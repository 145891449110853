import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import FullLogo from "./partials/FullLogo";
import Button from "../elements/Button";

import Dropdown from "react-bootstrap/Dropdown";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = e => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = e => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={classes} style={{ backgroundColor: "white" }}>
      <div className="container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <FullLogo />
          {/* <div className="col-4">
            <h3>Energy Demand Solutions</h3>
          </div> */}
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                    <li>
                      <Link to="/about-us" onClick={closeMenu}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      {/* <Link to="/our-services">Our Services</Link> */}
                      <Dropdown>
                        <Dropdown.Toggle
                          className="text-xc services-link"
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            borderColor: "white",
                            backgroundColor: "white",
                            color: "#9CA9B3",
                            fontFamily: "Inter"
                          }}
                          variant="success"
                          id="dropdown-basic"
                        >
                          Services
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#9CA9B3",
                              fontFamily: "Inter"
                            }}
                            href="/services"
                          >
                            Services
                          </Dropdown.Item>

                          <Dropdown.Item
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#9CA9B3",
                              fontFamily: "Inter"
                            }}
                            href="/services/direct-install-program"
                          >
                            Direct Install Program
                          </Dropdown.Item>
                          
                          <Dropdown.Item
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#9CA9B3",
                              fontFamily: "Inter"
                            }}
                            href="/services/residential-energy-assessments"
                          >
                            Residential Energy Assessments
                          </Dropdown.Item>
                          
                          <Dropdown.Item
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#9CA9B3",
                              fontFamily: "Inter"
                            }}
                            href="/services/verification-services"
                          >
                            Measurement and Verification Services
                          </Dropdown.Item>

                          <Dropdown.Item
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#9CA9B3",
                              fontFamily: "Inter"
                            }}
                            href="/services/energy-assessments-signup"
                          >
                            Energy Assessments
                          </Dropdown.Item>

                          <Dropdown.Item
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#9CA9B3",
                              fontFamily: "Inter"
                            }}
                            href="/services/analytics"
                          >
                            Data Analytics
                          </Dropdown.Item>

                          <Dropdown.Item
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#9CA9B3",
                              fontFamily: "Inter"
                            }}
                            href="/services/economic-impacts"
                          >
                            Economic Impacts
                          </Dropdown.Item>

                          <Dropdown.Item
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#9CA9B3",
                              fontFamily: "Inter"
                            }}
                            href="/services/references"
                          >
                            References
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    {/* <li>
                      <Link to="/faq">FAQ</Link>
                    </li> */}
                    {/* <li>
                    <a href="tel:5755194039"><strong>Call for appointment - 575-519-4039</strong> </a>
                    </li> */}
                    <li>
                      <Button className="contact-us-button">
                        <Link to="/contact-us" style={{ color: "white" }}>
                          Contact Us
                        </Link>
                      </Button>
                    </li>
                  </ul>
                  {/* {!hideSignin &&
                    <ul
                      className="list-reset header-nav-right"
                    >
                      <li>
                        <Link to="#0" className="button button-primary button-wide-mobile button-sm" onClick={closeMenu}>Sign up</Link>
                      </li>
                    </ul>} */}
                </div>
              </nav>
            </>
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#001a60",
            marginTop: "10px"
          }}
        >
          <p style={{ color: "white" }}>
            {" "}
            Call for appointment -
            <a href="tel:5755194039">
              <strong style={{ color: "white" }}> 575-519-4039</strong>{" "}
            </a>{" "}
            <span>/</span>{" "}
            <a href="tel:8443377283">
              {" "}
              <strong style={{ color: "white" }}>844-337-7283 (EDSSAVE) </strong>
            </a>
            <strong style={{ color: "white" }}>
              or Sign Up{" "}
              <Link
                style={{ color: "white", textDecorationLine: "underline" }}
                to="/services/energy-assessments-signup"
              >
                Here
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;

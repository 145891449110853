import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const EnergyAuditPrices = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);
  const [assessment, setAssessment] = useState("");
  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom text-color-primary"
              data-reveal-delay="200"
            >
              Home Energy Assessments Pricing
            </h1>
            <div className="row">
              <div className="col-sm-6">
                <p>
                  <strong>Standard</strong>
                </p>
                <strong>
                  Your Investment: $249 <br></br> Less Tax Credit: $150{" "}
                  <br></br> Your Cost: $99
                </strong>
                <p style={{ textAlign: "left" }}>
                  <strong>Includes:</strong>
                </p>
                <ul style={{ textAlign: "left" }}>
                  <li>
                    Analysis of utility bills to determine baseline usage, and
                    comparison to similar building type by climate zone
                  </li>
                  <li>
                    Thermographic inspection for air leakage through walls,
                    doors, windows, attic and crawlspaces
                  </li>
                  <li>Evaluation of insulation levels and condition</li>
                  <li>
                    Inventory of lighting appliances, and entertainment items
                    and real-time monitoring of high wattage units
                  </li>
                  <li>Evaluation of ‘vampire’ loads</li>
                  <li>Water temperature testing and adjustment</li>
                  <li>Installed during your assessment (if necessary)</li>
                  <li style={{ marginLeft: "30px" }}>20 LED light bulbs</li>
                  <li style={{ marginLeft: "30px" }}>
                    Pipe wrap exposed domestic hot water pipes
                  </li>
                  <li style={{ marginLeft: "30px" }}>
                    Weatherstrip main exterior door
                  </li>
                  <li style={{ marginLeft: "30px" }}>Big Gap Filler</li>
                  <li>
                    Detailed report with prioritized list of recommendation and
                    procedures for maximum energy savings
                  </li>
                  <li>Occupant behavior consultation/education</li>
                  <li style={{ marginLeft: "30px" }}>
                    Availability and qualification for rebates and tax credits
                  </li>
                  <li>
                    Other discounted energy efficiency products are available
                  </li>
                </ul>
                {/* {/* <Link
                  to="/services/energy-assessments-signup/form"
                  state={{ assessment: "Standard" }}
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                <Button
                  variant="outlined"
                >
                  Select
                </Button>
                </Link> */}
              </div>
              <div className="col-sm-6">
                <p>
                  <strong>Advanced</strong>
                </p>
                <strong>
                  Your Investment: $449 <br></br> Less Tax Credit: $150{" "}
                  <br></br> Your Cost: $299
                </strong>
                <p style={{ textAlign: "left" }}>
                  <strong>Includes:</strong>
                </p>
                <ul style={{ textAlign: "left" }}>
                  <li>
                    Everything included with the Standard assessments, plus:
                  </li>
                  <li style={{ marginLeft: "30px" }}>
                    Pressure diagnostics (Blower door test) to determine
                    quantity and locations of air leakage throughout the
                    residence
                  </li>
                  <li style={{ marginLeft: "30px" }}>
                    Determination of ASHRAE Building Airflow Standard for air
                    quality safety
                  </li>
                  <li style={{ marginLeft: "30px" }}>
                    Combustion appliance safety testing (when applicable)
                    including gas leak detection, carbon monoxide detection,
                    combustion efficiency, acceptable draft, and worst-case
                    conditions
                  </li>
                  <li>Installed during your assessment (if necessary):</li>
                  <li style={{ marginLeft: "30px" }}>
                    30 LED light bulbs and free installation
                  </li>
                  <li style={{ marginLeft: "30px" }}>
                    Weatherstrip all exterior doors
                  </li>
                </ul>
                {/* <Link
                  to="/services/energy-assessments-signup/form"
                  state={{ assessment: "Advanced" }}
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <Button
                    variant="outlined"
                  >
                    Select
                  </Button>
                </Link> */}
              </div>
            </div>
            <p>
              <strong>
                Starting January 2023, $150 tax credit for energy audits.
              </strong>
            </p>

            <Link
              to="/services/energy-assessments-signup/form"
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              <Button
                style={{
                  backgroundColor: "white",
                  border: "#9CA9B3 solid 1px"
                }}
              >
                Schedule Today!
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

EnergyAuditPrices.propTypes = propTypes;
EnergyAuditPrices.defaultProps = defaultProps;

export default EnergyAuditPrices;

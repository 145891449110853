import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const ReferencesList = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              General Information:
            </h1>
            <p>
              <ul>
                <li>
                  U.S. EPA Energy and Environment Guide to Action<br></br>
                  <a href="https://www.epa.gov/statelocalclimate/energy-and-environment-guide-action" target="_blank" >
                    https://www.epa.gov/statelocalclimate/energy-and-environment-guide-action
                  </a>
                </li>

                <li>
                  U.S. DOE Ratepayer-Funded Energy Efficiency<br></br>
                  <a href="https://www.energy.gov/oe/services/electricity-policy-coordination-and-implementation/state-and-regional-policy-assistanc-6" target="_blank" >
                    https://www.energy.gov/oe/services/electricity-policy-coordination-and-implementation/state-and-regional-policy-assistanc-6
                  </a>
                </li>

                <li>
                  State and Local Energy Efficiency Action Network (SEE Action){" "}
                  <br></br>
                  <a href="https://www.energy.gov/eere/state-and-local-energy-efficiency-action-network-see-action" target="_blank" >
                    https://www.energy.gov/eere/state-and-local-energy-efficiency-action-network-see-action
                  </a>
                </li>

                <li>
                  AEE CMVP Certification 
                  <br></br>
                  <a href = "https://www.aeecenter.org/certified-measurement-verification-professional/becoming-a-cmvp" target="_blank" >
                  https://www.aeecenter.org/certified-measurement-verification-professional/becoming-a-cmvp/
                  </a>

"
                </li>
              </ul>
            </p>
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              EM&V Related:
            </h1>
            <p>
              <ul>
                <li>
                  M&V Guidelines: Measurement & Verification for
                  Performance-Based Contracts, Version 4.0, U.S. DOE Federal
                  Energy Management Program (FEMP)<br></br>
                  <a href="https://www.energy.gov/sites/prod/files/2016/01/f28/mv_guide_4_0.pdf" target="_blank" >
                    https://www.energy.gov/sites/prod/files/2016/01/f28/mv_guide_4_0.pdf
                  </a>
                </li>
                <li>
                  DOE Uniform Methods Project (UMP) January 17, 2017<br></br>
                  <a href="https://www.energy.gov/eere/about-us/ump-protocols" target="_blank" >
                    https://www.energy.gov/eere/about-us/ump-protocols
                  </a>
                </li>
                <li>
                  SEE Action Energy Efficiency Program Impact Evaluation Guide
                  <br></br>
                  <a href=" https://www.energy.gov/eere/state-and-local-energy-efficiency-action-network-see-action" target="_blank" >
                    https://www.energy.gov/eere/state-and-local-energy-efficiency-action-network-see-action
                  </a>
                </li>
                <li>
                  International Performance Measurement and Verification
                  Protocol (IPMVP), Efficiency Valuation Organization (EVO)
                  <br></br>
                  <a href="https://evo-world.org/en/products-services-mainmenu-en/protocols/ipmvp" target="_blank" >
                    https://evo-world.org/en/products-services-mainmenu-en/protocols/ipmvp
                  </a>
                </li>
                <li>
                  California Energy Efficiency Evaluation Protocols: Technical,
                  Methodological, and Reporting Requirements for Evaluation
                  Professionals<br></br>
                  <a href="https://www.calmac.org/publications/EvaluatorsProtocols_Final_AdoptedviaRuling_06-19-2006.pdf" target="_blank" >
                    https://www.calmac.org/publications/EvaluatorsProtocols_Final_AdoptedviaRuling_06-19-2006.pdf
                  </a>
                </li>
              </ul>
            </p>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              ></p>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <Image
              className="has-shadow"
              src={require("./../../assets/images/Handymen.jpg")}
              alt="Hero"
              width={896}
              height={504}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

ReferencesList.propTypes = propTypes;
ReferencesList.defaultProps = defaultProps;

export default ReferencesList;

import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import checklist from './../../assets/images/business-management-todolist-svgrepo-com.svg'
import accuracy from './../../assets/images/business-management-megaphone-svgrepo-com.svg'

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const Mission = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Our Mission",
    paragraph: "To deliver solutions that ease the world's climate crisis"
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container center-content ">
        <SectionHeader data={sectionHeader} className="center-content" />
        <div className="row">
          <div className="col-md-3 ">
            <div className="reveal-from-bottom checklist-item">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <img
                    src={checklist}
                    alt="Features tile icon 01"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8"> Ensure Energy Savings</h4>
                <p className="m-0 text-sm">
                  We are interested in ensuring that Energy Efficiency Programs
                  are operating and reporting within International M&V
                  protocols.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 ">
            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="200"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <img
                    src={accuracy}
                    alt="Features tile icon 02"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Embrace Accuracy</h4>
                <p className="m-0 text-sm">
                  We provide a wide range of measurement, verification, and
                  analysis techniques for quantifying the results of resource
                  efficiency and resource management activities.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 ">
            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="400"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/business-management-megaphone-svgrepo-com.svg")}
                    alt="Features tile icon 03"
                    width={64}
                    height={64}
                  />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                   Transparent and Collaborative
                  </h4>
                  <p className="m-0 text-sm">
                      We maintain constant communication with our partners and stakeholders to ensure that our projects are meeting energy-efficiency goals, while optimizing cost.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 ">
            <div className="reveal-from-bottom  checklist-item">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/like.svg")}
                    alt="Features tile icon 04"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8"> Act With Respect</h4>
                <p className="m-0 text-sm">
                  We build good business relationships based on mutual trust and
                  delivering on agreed service levels. We pride ourselves on
                  getting the job done with quality and completeness of
                  execution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Mission.propTypes = propTypes;
Mission.defaultProps = defaultProps;

export default Mission;

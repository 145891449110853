import React from "react";

import EnergyAuditHero from "../../components/sections/EnergyAuditHero";

const EnergyAudits = () => {
    return (
    <>
      <EnergyAuditHero invertMobile topDivider imageFill className="illustration-section-02" />

    </>
  );
};

export default EnergyAudits;

import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const ServicesAssessmentSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Services",
    paragraph: ""
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}

                <h3 className="mt-0 mb-12 text-color-primary ">
                  <Link
                    to="/services/residential-energy-assessments"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Residential Energy Assessments
                  </Link>
                </h3>
                <p className="m-0">Evaulating energy waste in residences.</p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                {/* <Link
                  to="/services/installations"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                > */}
                <Link
                  to="/services/residential-energy-assessments"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <Image
                    title="U.S. Department of Energy"
                    src={require("./../../assets/images/energysaver2017_House.png")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </Link>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <Link
                  to="/services/direct-install-program"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  <h3 className="mt-0 mb-12 text-color-primary ">
                    Direct Install Program Management
                  </h3>
                </Link>
                <p className="m-0">
                  Utility/Government funded Energy Efficiency Programs for
                  evaluating homes and installing simple Energy Efficiency
                  Measures.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
               <Link
                  to="/services/direct-install-program"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                >
                <Image
                  title="Photo by UX Indonesia on Unsplash"
                  src={require("./../../assets/images/ux-indonesia-8mikJ83LmSQ-unsplash.jpg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                </Link>
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                        <Link
                  to="/services/verification-services"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                <h3 className="mt-0 mb-12 text-color-primary">
                  {/* <Link
                    to="/services/audits"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  > */}{" "}
                  Certified Measurement and Verification (CMVP)
                </h3>
                </Link>

                <p className="m-0">
                  Assessments that determine and document the energy-saving and
                  financial benefits along with indirect benefits of an energy
                  efficiency program.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                            <Link
                  to="/services/verification-services"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}>
                <Image
                  title="Photo by Carlos Muza on Unsplash"
                  src={require("./../../assets/images/carlos-muza-hpjSkU2UYSU-unsplash.jpg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
                </Link>
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  Rebate Processing
                </h3>
                <p className="m-0">
                  Verify rebate applications to ensure compliance with utility
                  and/or regulatory agency requirements.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Department of Energy"
                  src={require("./../../assets/images/Tax credits and rebates_4.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ServicesAssessmentSplit.propTypes = propTypes;
ServicesAssessmentSplit.defaultProps = defaultProps;

export default ServicesAssessmentSplit;

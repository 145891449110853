import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const ReportingChecklist = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Processing and Reporting",
    paragraph:
      "kWh, kW and incentive reporting for Utility and \n Measurement and Validation (M&V) use"
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container center-content ">
        <SectionHeader data={sectionHeader} className="center-content" />
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="reveal-from-bottom checklist-item">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-check-circle-100.png")}
                    alt="Features tile icon 01"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Work Orders</h4>
                <p className="m-0 text-sm">
                  Comprehensive Reporting for timeframe required.
                </p>
              </div>
            </div>

            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="200"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-check-circle-100.png")}
                    alt="Features tile icon 02"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Statement of Work Management</h4>
                <p className="m-0 text-sm">
                  Proactive contract management.
                </p>
              </div>
          </div>

          <div
            className="reveal-from-bottom checklist-item"
            data-reveal-delay="400"
          >
            <div className="features-tiles-item-header">
              <div className="features-tiles-item-image mb-16">
                <Image
                  src={require("./../../assets/images/icons8-check-circle-100.png")}
                  alt="Features tile icon 03"
                  width={64}
                  height={64}
                />
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Energy Saved</h4>
                <p className="m-0 text-sm">
                  Performance to Goal
                </p>
              </div>
            </div>
            </div>
          </div>
          {/* End of 1st Column */}

          {/* 2nd Column */}
         
        </div>

      </div>
    </section>
  );
};

ReportingChecklist.propTypes = propTypes;
ReportingChecklist.defaultProps = defaultProps;

export default ReportingChecklist;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Audit from '../../views/services/Audit';
import { Button } from '@mui/material';
import { ButtonGroup } from 'react-bootstrap';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const AuditSignup = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan" style={{textAlign:"center"}}>
            <h3 className="m-0" >
               Signup for An Appointment
              </h3>
          </div>
          <div className="cta-action">
              <ButtonGroup>Placeholder</ButtonGroup>
          </div>
        </div>
      </div>
    </section>
  );
}

AuditSignup.propTypes = propTypes;
AuditSignup.defaultProps = defaultProps;

export default AuditSignup;
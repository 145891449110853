import React from "react";

import EnergyAuditPrices from "../../components/sections/EnergyAuditPrices";

const EnergyAuditsPrices = () => {
    return (
    <>
      <EnergyAuditPrices invertMobile topDivider imageFill className="illustration-section-02" />

    </>
  );
};

export default EnergyAuditsPrices;

import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types
};

const defaultProps = {
  ...SectionSplitProps.defaults
};

const ServicesMVSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Measurement and Verification Services",
    paragraph: ""
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  {/* <Link
                    to="/services/installations"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  > */}
                  Program Process Evaluation
                  {/* </Link> */}
                </h3>
                <p className="m-0">
                  Systematic assessments of energy efficiency programs by
                  documenting program operations and identifying and proposing
                  improvements.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                {/* <Link
                  to="/services/installations"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                > */}
                <Image
                  title="U.S. Department of Energy"
                  src={require("./../../assets/images/energysaver2017_House.png")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                {/* </Link> */}
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">
                  {/* <Link
                    to="/services/installations"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  > */}
                  System of Record Evaluation
                  {/* </Link> */}
                </h3>
                <p className="m-0">
                  Ensuring necessary data are collected to track program
                  activity and calculate savings.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                {/* <Link
                  to="/services/installations"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                > */}
                <Image
                  title="Photo by UX Indonesia on Unsplash"
                  src={require("./../../assets/images/ux-indonesia-8mikJ83LmSQ-unsplash.jpg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                {/* </Link> */}
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  {/* <Link
                    to="/services/audits"
                    style={{ color: "#1CB68B", textDecoration: "none" }}
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  > */}{" "}
                  Economic Impact Evaluation
                  {/* </Link> */}
                </h3>
                <p className="m-0">
                  Assessments that determine and document the energy-saving and
                  financial benefits along with indirect benefits of an energy
                  efficiency program.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Photo by Carlos Muza on Unsplash"
                  src={require("./../../assets/images/carlos-muza-hpjSkU2UYSU-unsplash.jpg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary ">Survey</h3>
                <p className="m-0">
                  Phone, email or/and webform surveys to contact
                  consumers to ensure that Energy Efficiency equipment are
                  actually being used.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Photo by Petr Machacek on Unsplash"
                  src={require("./../../assets/images/petr-machacek-BeVGrXEktIk-unsplash.jpg")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
                <p></p>
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  Market Feedback Analysis
                </h3>
                <p className="m-0">
                  Assess which people and places are receiving the greatest
                  benefits and inversely identify where current projects need to be
                  extended and where future programs need to be implemented.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Photo by Jon Tyson on Unsplash"
                  src={require("./../../assets/images/jon-tyson-vVSleEYPSGY-unsplash.jpg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Lightning fast workflow
                  </div> */}
                <h3 className="mt-0 mb-12 text-color-primary">
                  Market Evaluation
                </h3>
                <p className="m-0">
                  Assessment of the demographics of Energy Efficiency program
                  participants and market changes that result from one or more
                  program efforts.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  title="Photo by Campaign Creators on Unsplash"
                  src={require("./../../assets/images/campaign-creators-pypeCEaJeZY-unsplash.jpg")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ServicesMVSplit.propTypes = propTypes;
ServicesMVSplit.defaultProps = defaultProps;

export default ServicesMVSplit;

import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const MVChecklist = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Energy Measurement and Validation Checklist",
    paragraph:
      ""
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container center-content ">
        <SectionHeader data={sectionHeader} className="center-content" />
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="reveal-from-bottom checklist-item">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-1st-96.png")}
                    alt="Features tile icon 01"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Create a Baseline</h4>
                <p className="m-0 text-sm">
                  Determine the Energy Demand before Energy Efficiency Methods
                  are applied.
                </p>
              </div>
            </div>

            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="200"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-2-96.png")}
                    alt="Features tile icon 02"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Analyze Energy Efficiency Savings</h4>
                <p className="m-0 text-sm">
                  Incorporate the time, location and duration(EUL) of EE
                  measures into the savings calculations.
                </p>
              </div>
            </div>

            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="400"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-3-96.png")}
                    alt="Features tile icon 03"
                    width={64}
                    height={64}
                  />
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Determine the Best Fit Method</h4>
                  <p className="m-0 text-sm">
                    Establish the best fit method to determine the savings
                    possible after EE methods are implemeneted, choosing between
                    deemed savings, direct measurement and verification (M&V),
                    or Comparison group methods.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End of 1st Column */}

          {/* 2nd Column */}
          <div className="col-12 col-md-6">
            <div className="reveal-from-bottom  checklist-item">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-4-96.png")}
                    alt="Features tile icon 04"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">Verify Energy Savings</h4>
                <p className="m-0 text-sm">
                  Confirm Energy Efficiency Methods are implemented effectively
                  in projects by visual inspection, surveys or review of program
                  documentation/reports.
                </p>
              </div>
            </div>
            <div
              className="reveal-from-bottom checklist-item"
              data-reveal-delay="200"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-5-96.png")}
                    alt="Features tile icon 05"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">
                  Accounting for unforeseen independent variables that affect
                  Savings{" "}
                </h4>
                <p className="m-0 text-sm">
                  Independent variables such as irregular weather, increased
                  production levels, changes in occupancy of a facility that
                  affect Energy Savings are calcuated into the final analysis.
                </p>
              </div>
            </div>

            <div
              className="reveal-from-bottom  checklist-item"
              data-reveal-delay="400"
            >
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require("./../../assets/images/icons8-circled-6-96.png")}
                    alt="Features tile icon 06"
                    width={64}
                    height={64}
                  />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">
                  Verify the Accuracy of the Estimate
                </h4>
                <p className="m-0 text-sm" style={{ textAlign: "center" }}>
                  Determine errors that could affect the accuracy of estimated
                  Energy Savings and describe the process to minimize the impact
                  on Energy Savings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

MVChecklist.propTypes = propTypes;
MVChecklist.defaultProps = defaultProps;

export default MVChecklist;

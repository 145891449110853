import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

const DirectInstallProgramBenefits = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
              style={{ textAlign: "center" }}
            >
              Why Choose Us?
            </h1>
            <p>
              Energy Demand Solutions (EDS) is a certified and professionally
              managed Service Provider in Taos County, New Mexico. Established
              in 2022 as a 'C' Corporation, EDS is recognized for its Certified
              Measurement & Verification Professionals (CMVP) expertise and
              holds certifications from the Association of Energy Engineers and
              the Building Performance Institute (BPI). EDS provides the tools
              and expertise to combat unnecessary demand on the energy grid. In
              coordination with our utility/government partners, we identify the
              source of wasted energy and replace it with top-rated energy
              efficient solutions.
            </p>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              ></p>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <Image
              className="has-shadow"
              src={require("./../../assets/images/Handymen.jpg")}
              alt="Hero"
              width={896}
              height={504}
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

DirectInstallProgramBenefits.propTypes = propTypes;
DirectInstallProgramBenefits.defaultProps = defaultProps;

export default DirectInstallProgramBenefits;
